<template>
    <div class="file-wrapper">
        <div class="file-title">
            <p class="second-title">{{ title }}</p>
            <p class="last-time">
                最後上傳時間：<span class="time">{{ updatedAt ?? '-' }}</span>
            </p>
        </div>

        <div class="upload-container">
            <div class="upload">
                <div class="file-selection">
                    <Button
                        :width="150"
                        iconClass="upload-btn"
                        buttonWord="選擇上傳檔案"
                        buttonSize="default"
                        buttonStyle="blue-hollow"
                        @click="clickUploadInput"
                    />
                    <input
                        accept=".xlsx"
                        :id="`selected-product${customType + indexKey}`"
                        type="file"
                        :name="`selected-product${customType + indexKey}`"
                        @change="selectedLocalFile"
                        hidden
                    />
                    <p :class="{ 'has-file': localFile }">
                        {{ fileName }}
                        <span v-if="uploadError">*{{ uploadError }}</span>
                    </p>
                </div>
                <Button
                    v-if="!isUploadLoading"
                    buttonWord="上傳"
                    buttonStyle="blue"
                    :disabled="isUploadDisabled"
                    @click="uploadProduct()"
                />
                <Button
                    v-else
                    buttonWord="上傳中"
                    iconClass="process-loading-icon"
                    buttonSize="default"
                    buttonStyle="hollow"
                    class="upload-loading disabled"
                    :disabled="true"
                />
            </div>

            <div class="download">
                <div class="file-selection">
                    <template v-if="!isDownloading">
                        <Button
                            :width="150"
                            iconClass="download-excel-btn"
                            buttonWord="下載檔案"
                            buttonSize="default"
                            buttonStyle="blue-hollow"
                            @click="downloadProduct"
                        />
                    </template>
                    <Button
                        v-else
                        :width="150"
                        buttonWord="下載中"
                        iconClass="process-loading-icon"
                        buttonSize="default"
                        buttonStyle="hollow"
                        class="download-loading disabled"
                    />
                    <p>下載內容為上次編輯的檔案</p>
                </div>
            </div>
            <p
                v-if="uploadResult"
                @click="
                    ;(isShowResultModal = true),
                        this.$setGaEvent(
                            `showResultModal_${customType}`,
                            'show_modal'
                        )
                "
                class="checked-result"
            >
                查看檔案編輯結果
            </p>
        </div>
    </div>
    <ProductResultModal
        v-model="isShowResultModal"
        :isUploadSuccess="isUploadSuccess"
        :result="uploadResult"
        :title="title"
    />
</template>

<script>
import Button from '@/components/Button.vue'
import ProductResultModal from '@/containers/backstage/ProductResultModal.vue'
import {
    postBackstageDownloadProductAPI,
    postBackstageUploadProductAPI
} from '@/assets/javascripts/api'

export default {
    name: 'ProductFiles',
    components: {
        Button,
        ProductResultModal
    },
    emits: ['reload'],
    props: {
        title: {
            type: String,
            default: '標題'
        },
        customType: {
            type: String,
            default: null
        },
        updatedAt: {
            type: String,
            default: '2010.01.01 00:00'
        },
        indexKey: {
            type: [String, Number]
        }
    },
    methods: {
        downloadProduct: async function () {
            this.$setGaEvent(
                `DownloadProductFile_${this.customType}`,
                'click-button'
            )
            try {
                this.isDownloading = true
                const payload = { customType: this.customType }
                const res = await postBackstageDownloadProductAPI(payload)
                const target = window.open('_blank', '_self')
                target.location = res?.data?.urls[0]
            } catch {
                this.$showErrorMessage(
                    '下載時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.isDownloading = false
            }
        },
        uploadProduct: async function () {
            if (!this.localFile || this.uploadError) return
            this.isUploadLoading = true
            let formData = new FormData()
            formData.append('file', this.localFile)
            formData.append('customType', this.customType)
            this.$setGaEvent(
                `uploadProductFile_${this.customType}`,
                'click-button'
            )

            try {
                let res = await postBackstageUploadProductAPI(formData)
                this.uploadResult = res.data
                this.isUploadSuccess = true
                if (this.uploadResult?.validCount > 0) {
                    this.$emit('reload')
                }
                this.isShowResultModal = true
            } catch (error) {
                this.isUploadSuccess = false
                this.uploadResult = error?.response?.data ?? null
                if (!this.uploadResult) {
                    this.$showErrorMessage(
                        '上傳時出現錯誤，請稍後再試',
                        '若持續出現此問題，請聯繫資訊客服'
                    )
                } else {
                    this.isShowResultModal = true
                }
            } finally {
                this.isUploadLoading = false
            }
        },
        clickUploadInput: function () {
            document
                .querySelector(
                    `#selected-product${this.customType + this.indexKey}`
                )
                .click()
            this.$setGaEvent('selectedProductLocalFile', 'click-Button')
        },
        selectedLocalFile: function (event) {
            const files = event.target.files || event.dataTransfer.files || null
            if (!files[0]) return
            this.localFile = files[0]
            this.fileName = this.localFile.name
            const isValidType =
                this.fileName.substring(this.fileName.lastIndexOf('.')) ===
                '.xlsx'
            if (!isValidType) this.uploadError = '檔案類型錯誤'
            else this.uploadError = ''
        }
    },
    computed: {
        isUploadDisabled: function () {
            return !this.localFile || this.uploadError ? true : false
        }
    },
    watch: {},
    data() {
        return {
            isDownloading: false,
            isNoPicked: false,
            hasDownloadError: false,
            downloadUrls: [],
            localFile: null,
            fileName: '檔案名稱',
            uploadError: '',
            isUploadSuccess: null,
            uploadResult: null,
            isUploadLoading: false,
            isShowResultModal: false
        }
    },
    async mounted() {}
}
</script>

<style lang="scss" scoped>
p {
    margin: 0;
}
.file-title {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $sixth-black;
    font-weight: 500;
    .second-title {
        font-size: 14px;
    }
    .last-time {
        font-size: 12px;
    }
}

.upload-container {
    box-shadow: 0px 0px 1px $secondary-grey inset;
    border-radius: 3px;
    padding: 20px;
    @media screen and (max-width: 576px) {
        padding: 10px;
    }
}

.file-selection {
    display: flex;
    align-items: center;
    // margin-bottom: 20px;
    p {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: $fourth-black-disabled;
        &.has-file {
            color: $sixth-black;
            margin-right: 10px;
        }
        span {
            color: $primary-red;
            font-size: 12px;
        }
    }
    @media screen and (max-width: 576px) {
        display: flex;
        flex-wrap: wrap;
        p {
            margin-top: 5px;
        }
    }
    @media screen and (max-width: 420px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.upload {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    word-break: break-all;

    > :deep(button) {
        margin-bottom: 10px;
        margin-left: auto;
    }

    .file-selection {
        margin-bottom: 10px;
    }
}

.checked-result {
    text-align: right;
    font-size: 14px;
    color: $fourth-blue;
    text-decoration: underline;
    cursor: pointer;
}
</style>
